<script setup lang="ts">
defineOptions({
  name: "CheckoutLayout",
});
const { t } = useI18n();

// Navigation for default theme (depth 1 is 2 level navi, depth 2 is 3 level navi, and so on)
const { loadNavigationElements } = useNavigation();
const { data } = useAsyncData("mainNavigation", () => {
  return loadNavigationElements({ depth: 2 });
});
provide("swNavigation-main-navigation", data);

const { loadNavigationElements: loadFooterNavigationElements } = useNavigation({
  type: "footer-navigation",
});
const { data: footerData } = useAsyncData("mainFooterNavigation", () => {
  return loadFooterNavigationElements({ depth: 1 });
});
provide("swNavigation-footer-navigation", footerData);

const { loadNavigationElements: loadTopNavigationElements } = useNavigation({
  type: "service-navigation",
});
const { data: topNavigationData } = useAsyncData(
  "mainServiceNavigation",
  () => {
    return loadTopNavigationElements({ depth: 2 });
  }
);
provide("swNavigation-service-navigation", topNavigationData);

useBreadcrumbs([
  {
    name: t("checkout.title"),
    path: "/checkout",
  },
]);
</script>
<template>
  <div class="checkout-layout-wrapper mt-[60px] md:mt-[85px]">
    <LayoutHeader />
    <div class="checkout-breadcrumb">
      <div class="max-w-auto xl:max-w-[1190px] !mx-auto px-4 relative">
        <LayoutBreadcrumbs />
      </div>
    </div>
    <main class="mt-8 checkout-page">
      <LayoutNotifications />
      <slot />
    </main>
    <LayoutFooter />
  </div>
</template>
